import axios from "@/axios"
export default {
  name: 'login-success',
  components: {},
  props: [],
  data() {
    return {
      auth_code: null,
    }
  },
  computed: {

  },
  async mounted() {
    const auth_code = this.$route?.query?.code ?? null
    await this.getAuthToken(auth_code)
  },
  methods: {
    async getAuthToken(code) {
      if (code) {
        try {
          const response = await axios.post(`user-auth/code`, {
            code: code
          })
          if (response?.data) {
            let user = {}
            let {user_id,email,user_name} = response?.data
            user = {user_id, email, user_name }
            this.$store.dispatch('setLoginSession', {token: response?.data?.access_token, userLoggedIn: true, user})
          }
        } catch (error) {
          // this.$store.dispatch('logoutUser')
        }
        this.$router.go({
          name:"dashboard.view"
        }).catch(e => e)
      }
    }
  }
}


